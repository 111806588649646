import React from "react";
import { RewardCard } from "../RewardComponent";
import { useCartContext } from "../../../../system/CartProvider";
import { useNoSessionContext } from "../../../../system/NoSessionProvider";
import { useValeStackNavigation } from "../../../../nav/nav_utils";

//currentPoints: number | undefined
type KoozieRewardCardRewardCardProps = {
    currentPoints: number | undefined
    points: number
}
const KoozieRewardCard: React.FC<KoozieRewardCardRewardCardProps> = (props) => {
    const cart = useCartContext();
    const menu = useNoSessionContext();
    const navigation = useValeStackNavigation();

    const onPress = () => {
        const discMenuItem = menu?.menuItems?.values()?.find((item) => item.name?.toLowerCase() === 'koozie') //TODO: remove this;
        if (!discMenuItem) {
            console.error('Koozie not found in menu');
            return
        }

        cart?.addItem({ paymentVariant: 'vibes', itemTypeId: discMenuItem?.id!, itemTypeVariationId: discMenuItem?.variations?.[0]?.id!, price: { amount: 0 }, name: 'Koozie' });
        navigation.push("order", { screen: "cart", params: {} })
    }

    const locked: boolean = (props.currentPoints || 0) < 400
    return <RewardCard
        name={'Koozie Can-Ception'}
        imageUrl={'./images/loyalty/loyalty_koozie.jpg'}
        onPress={onPress}
        locked={locked}
    />
}

export default KoozieRewardCard;